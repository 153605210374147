// src/components/CourseDetails.tsx
import React from 'react';

interface CourseDetailsProps {
  title: string;
  content: string;
}

const CourseDetails: React.FC<CourseDetailsProps> = ({ title, content }) => {
  return (
    <div className="course-details">
      <h2>{title}</h2>
      <p>{content}</p>
    </div>
  );
};

export default CourseDetails;
