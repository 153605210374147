import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios for API calls
import { useAuth } from '../../context/AuthContext';
import './Login.css'; // Import custom CSS if needed

const Login: React.FC = () => {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [loading, setLoading] = useState(false); // State to handle loading
  const [error, setError] = useState<string | null>(null); // State to handle errors
  const navigate = useNavigate();
  const { login } = useAuth(); // Use login function from AuthContext

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true); // Show loading state
    setError(null); // Reset error state

    try {
      // API call to login (replace URL with your actual API endpoint)
      const response = await axios.post('https://mukundprogrammingtutorials.in/mukundprogrammingtutorilasapi/public/api/users/login', credentials);

      // Handle API response
      if (response.status === 200) {
        login(response.data.user.email); // Simulate login action
        navigate('/dashboard'); // Redirect to dashboard on success
      } else {
        setError('Invalid credentials. Please try again.'); // Handle invalid credentials
      }
    } catch (err: any) {
      setError('An error occurred. Please try again later.'); // Handle any other errors
    } finally {
      setLoading(false); // Hide loading state
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center logins">
        <div className="col-md-6 col-lg-4">
          <div className="card shadow">
            <div className="card-body">
              <h2 className="card-title text-center mb-4">Access your data</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={credentials.email}
                    onChange={handleChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="mb-3 input-group">
                  <input
                    type={showPassword ? 'text' : 'password'} // Toggle input type
                    name="password"
                    placeholder="Password"
                    value={credentials.password}
                    onChange={handleChange}
                    className="form-control"
                    required
                  />
                  <span
                    className="input-group-text bg-white border-0 passIcon"
                    style={{ cursor: 'pointer' }}
                    onClick={togglePasswordVisibility}
                  >
                    <i className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                  </span>
                </div>
                {error && <div className="alert alert-danger">{error}</div>} {/* Display error if any */}
                <button type="submit" className="btn btn-primary w-100" disabled={loading}>
                  {loading ? 'Logging in...' : 'Login'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
