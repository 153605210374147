import Cookies from 'js-cookie';
import { Course } from '../data/coursesData';

const CART_COOKIE_NAME = 'user_cart';

interface CartItem {
  courseId: number;
  title: string;
  fee: number;
  quantity: number;
}

export const getCart = (): CartItem[] => {
  const cart = Cookies.get(CART_COOKIE_NAME);
  return cart ? JSON.parse(cart) : [];
};

export const addToCart = (course: Course) => {
  const cart = getCart();
  const existingItem = cart.find((item) => item.courseId === course.id);

  if (existingItem) {
    existingItem.quantity += 1;
  } else {
    cart.push({
      courseId: course.id,
      title: course.title,
      fee: course.fee,
      quantity: 1,
    });
  }

  Cookies.set(CART_COOKIE_NAME, JSON.stringify(cart), { expires: 7 });
  emitCartUpdate();
};

export const removeFromCart = (courseId: number) => {
  const cart = getCart();
  const updatedCart = cart.filter((item) => item.courseId !== courseId);

  Cookies.set(CART_COOKIE_NAME, JSON.stringify(updatedCart), { expires: 7 });
  emitCartUpdate();
};

export const clearCart = () => {
  Cookies.remove(CART_COOKIE_NAME);
  emitCartUpdate();
};

const emitCartUpdate = () => {
  const event = new Event('cartUpdated');
  window.dispatchEvent(event);
};
