import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Axios for API calls
import { useAuth } from '../../context/AuthContext';
import './Profile.css'; // Import your custom CSS for the profile

const Profile: React.FC = () => {
  const [user, setUser] = useState<any>(null); // Store user profile data
  const [loading, setLoading] = useState(true); // Loading state for fetching data
  const [error, setError] = useState<string | null>(null); // Error state for handling errors
  const navigate = useNavigate();
  const { logout } = useAuth(); // Logout function from AuthContext

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        // Replace with your actual API endpoint for fetching user profile data
        const email = localStorage.getItem("email")
        const response = await axios.get(`https://mukundprogrammingtutorials.in/mukundprogrammingtutorilasapi/public/api/users/${email}`);

        if (response.status === 200) {
          setUser(response.data); // Store user data in state
        } else {
          setError('Failed to load profile. Please try again later.');
        }
      } catch (err: any) {
        setError('Error fetching profile data. Please try again later.');
      } finally {
        setLoading(false); // End loading after data is fetched
      }
    };

    fetchUserProfile();
  }, []); // Fetch profile data when component mounts

  const handleLogout = () => {
    logout(); // Call logout function from context
    navigate('/login'); // Redirect to login page after logout
  };

  if (loading) {
    return (
      <div className="container profiles">
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-4">
            <div className="card shadow">
              <div className="card-body text-center">
                <h2>Loading Profile...</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container profiles">
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-4">
            <div className="card shadow">
              <div className="card-body text-center">
                <h2>{error}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container profiles">
      <div className="row justify-content-center">
        <div className="col-md-6 col-lg-4">
          <div className="card shadow">
            <div className="card-body">
              <h2 className="card-title text-center mb-4">Profile</h2>
              <div className="mb-3">
                <strong>Name:</strong>
                <p>{user.name}</p>
              </div>
              <div className="mb-3">
                <strong>Email:</strong>
                <p>{user.email}</p>
              </div>
              <div className="mb-3">
                <strong>Mobile:</strong>
                <p>{user.mobile}</p>
              </div>
              <button className="btn btn-danger w-100" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
