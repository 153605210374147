// src/data/coursesData.ts

export interface Course {
  id: number;
  title: string;
  description: string;
  image: string;
  fee: number;
  rating: number;
  numberOfTopics: number;
  badgeText: string; // Badge text (e.g., "Premium")
  source: string; // Add this property to define if the course is local or external
  externalLink?: string; // Optional external link
  categoryDisplayId?: string;
}

const courses: Course[] = [
  {
    id: 1,
    title: 'Save Registration form in JS',
    description: 'How to store form data in local storage using JavaScript | Save Registration form in JS',
    image: '/assets/images/courses/Localstorage.png',
    fee: 0,
    rating: 4.5,
    numberOfTopics: 10,
    badgeText: 'Standard',
    source: 'youtube',
    externalLink: "https://www.youtube.com/watch?v=3LrB0HzAOHY",
    categoryDisplayId: "WebDevelopment"
  },
  {
    id: 6,
    title: 'Learn How to Create, Publish, and Use Angular Library on NPM: Step-by-Step Guide',
    description: 'complete process of creating a reusable Angular  library, publishing it on npm, and integrating it into another Angular project.',
    image: '/assets/images/courses/Angular-18-Library-Create-Publish-Use.png',
    fee: 0,
    rating: 4.4,
    numberOfTopics: 16,
    badgeText: 'Premium',
    source: 'youtube',
    externalLink: "https://youtu.be/r7zrXeNgO9k",
    categoryDisplayId: "WebDevelopment"
  },
  {
    id: 2,
    title: 'Login form authentication in JS',
    description: 'Login form authentication in JS with local storage | Profile page | Logout Feature | Web Storage API',
    image: '/assets/images/courses/login-form-with-localstorage-js.png',
    fee: 0,
    rating: 4.7,
    numberOfTopics: 15,
    badgeText: 'Free',
    source: 'youtube',
    externalLink: "https://www.youtube.com/watch?v=3LrB0HzAOHY",
    categoryDisplayId: "WebDevelopment"
  },
  {
    id: 4,
    title: 'Learn how to Create SEO sitemap for website',
    description: 'Create SEO sitemap for website, submit sitemap in google.google TXT record in hostinger.com DNS.',
    image: '/assets/images/courses/best-sitemap-generator-tools-featured.webp',
    fee: 0,
    rating: 4.6,
    numberOfTopics: 12,
    badgeText: 'Premium',
    source: 'youtube',
    externalLink: "https://www.youtube.com/watch?v=hD4JuE9oVD0",
    categoryDisplayId: "Digital-Marketing"
  },
  {
    id: 3,
    title: 'How to design organization chart in angular 8,10 in hindi/english',
    description: 'Example of Organization chart in angular. Visualize data in normal way.',
    image: '/assets/images/courses/organization-chart-horizontal.png',
    fee: 10,
    rating: 4.8,
    numberOfTopics: 8,
    badgeText: 'Standard',
    source: 'youtube',
    externalLink: "https://youtu.be/mamGCPXV1Rw",
    categoryDisplayId: "WebDevelopment"
  },

  {
    id: 5,
    title: 'Session in Flask with login form authentication.User data on the dashboard and Logout handling',
    description: 'Session in Flask with login form authentication.User data on the dashboard and Logout handling. Explore Flask session management, login authentication, dynamic dashboards, and seamless logout handling for robust web development. Elevate your skills!',
    image: '/assets/images/courses/Session-in-Flask-with-login-form-authentication.png',
    fee: 0,
    rating: 4.9,
    numberOfTopics: 20,
    badgeText: 'Free',
    source: 'youtube',
    externalLink: "https://youtu.be/eQBFKU_5ybc",
    categoryDisplayId: "BackendDevelopment"
  }

];

export default courses;
