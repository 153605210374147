// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

import './styles.css'; // Your custom styles
import 'font-awesome/css/font-awesome.min.css';
import { CartProvider } from './context/CartContext';
import { AuthProvider } from './context/AuthContext';
import GoogleTagManager from './GoogleTagManager'; // Adjust the import path as needed

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <CartProvider>
      <GoogleTagManager />
        <App />
      </CartProvider>
    </AuthProvider>
  </React.StrictMode>
);
