// src/pages/Course.tsx
import React from 'react';
import { useParams } from 'react-router-dom';
import CourseDetails from '../components/CourseDetails';

const Course: React.FC = () => {
  const { courseId } = useParams<{ courseId?: string }>(); // Make courseId optional

  const courseDetails: Record<string, { title: string; content: string }> = {
    1: {
      title: 'React for Beginners',
      content: 'This course covers the basics of React, including components, state, and props.',
    },
    2: {
      title: 'Advanced TypeScript',
      content: 'Dive deeper into TypeScript with advanced topics and practical examples.',
    },
  };

  // Check if courseId is defined before accessing courseDetails
  const course = courseId ? courseDetails[courseId] : undefined;

  return (
    <div className="course">
      {course ? (
        <CourseDetails title={course.title} content={course.content} />
      ) : (
        <h2>Course not found</h2>
      )}
    </div>
  );
};

export default Course;
