import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Course from './pages/Course';
import Footer from './components/Footer';
import Courses from './pages/Courses';
import courses from './data/coursesData'; // Import the courses data
import './styles.css';
import CartPage from './pages/CartPage';
import Login from './pages/authentication/Login';
import Signup from './pages/authentication/Signup';
import Profile from './pages/profile/Profile';

const App: React.FC = () => {
  return (
    <Router>
      <Navbar />
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/courses" element={<Courses courses={courses} />} /> {/* Pass the courses prop */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/course/:courseId" element={<Course />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/dashboard" element={<Home />} />
          <Route path="/profile" element={<Profile />} />

        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
