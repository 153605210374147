import React from 'react';
import { Course } from '../data/coursesData';
import { addToCart } from '../services/CartService'; // Import the addToCart function
import './CourseCard.css'; // Optional styling
import { Link } from 'react-router-dom';
import { useCart } from '../context/CartContext';

interface CourseCardProps {
  course: Course;
  onAddToCart: (course: Course) => void; // Pass the course to the handler
}

const CourseCard: React.FC<CourseCardProps> = ({ course, onAddToCart }) => {
  const { updateCartItems } = useCart();
  const handleAddToCart = () => {
    addToCart(course); // Add the course to the cart
    updateCartItems(); // Update the cart count in Navbar
  };
  const {
    id,
    title,
    description,
    image,
    fee,
    rating,
    numberOfTopics,
    badgeText,
    source,
    externalLink
  } = course;

  return (
    <div className="card course-card">
      <div className="position-relative">
        <img src={image} alt={title} className="card-img-top course-image" />
        <span className="badge bg-warning position-absolute top-0 start-0 m-2">{badgeText}</span>
      </div>
      <div className="card-body">
        <h5 className="card-title truncate1" title={title}>{title}</h5>
        <p className="card-text truncate" title={description}>{description}</p>
        <div className="course-details">
          <span>Fee: {fee === 0 ? 'Free' : `$${fee}`}</span>
          <span>Rating: {rating} ⭐</span>
          <span>Topics: {numberOfTopics}</span>
        </div>
        {/* Handling View button */}
        {source !== 'local' ? (
          // External link
          <a href={externalLink} target="_blank" rel="noopener noreferrer" className="btn btn-warning mt-2">
            View
          </a>
        ) : (
          // Internal link
          <Link to={`/course-details/${id}`} className="btn btn-info mt-2">
            View
          </Link>
        )}

        {/* Add to Cart Button */}
        <button className="btn btn-secondary mt-2" onClick={handleAddToCart}>Add to Cart</button>
      </div>
    </div>
  );
};

export default CourseCard;
