// src/pages/Contact.tsx
import React from 'react';
import './Contact.css'; // Optional: You can create a CSS file for styling

const Contact: React.FC = () => {
  return (
    <div className="contact">
      <h1 className="header">Contact Us</h1>
      <p>Email: <a href="mailto:support@mukundprogrammingtutorials.in">support@mukundprogrammingtutorials.in</a></p>
      <p>Mobile: <a href="tel:+918677040031">8677040031</a></p>
      <p>
        WhatsApp: 
        <a href="https://wa.me/918677040031" target="_blank" rel="noopener noreferrer">
          Chat with us!
        </a>
      </p>
    </div>
  );
};

export default Contact;
