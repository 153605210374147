import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { useAuth } from '../context/AuthContext'; // Custom Auth context
import './Navbar.css';

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { cartItems } = useCart(); // Use cartItems from context
  const { isAuthenticated, logout } = useAuth(); // Use Auth context

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img
            src="https://yt3.ggpht.com/okwHkRkY-r6FzulnoCNk2pFY7W4genVlT9YfAtiFTfhrf5mTjfHGXR0ytqyHPgBeWWMsdZj1sBg=s600-c-k-c0x00ffffff-no-rj-rp-mo"
            alt="Mukund Programming Tutorials Logo"
            className="navbar-logo"
          />
          Mukund Programming Tutorials
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleMenu}
          aria-controls="navbarNav"
          aria-expanded={isOpen}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
          <div className="navbar-nav">
            <Link className="nav-link" to="/" onClick={() => setIsOpen(false)}>
              Home
            </Link>
            <Link className="nav-link" to="/courses" onClick={() => setIsOpen(false)}>
              Courses
            </Link>
            <Link className="nav-link" to="/about" onClick={() => setIsOpen(false)}>
              About
            </Link>
            <Link className="nav-link" to="/contact" onClick={() => setIsOpen(false)}>
              Contact
            </Link>
            <Link className="nav-link" to="/cart" onClick={() => setIsOpen(false)}>
              <i className="cart-icon fa fa-shopping-cart"></i> ({cartItems})
            </Link>
            {isAuthenticated ? (
              <>
                <Link className="nav-link" to="/profile" onClick={() => setIsOpen(false)}>
                  Profile
                </Link>
              </>
            ) : (
              <>
                <Link className="nav-link" to="/login" onClick={() => setIsOpen(false)}>
                  Login
                </Link>
                <Link className="nav-link" to="/signup" onClick={() => setIsOpen(false)}>
                  Signup
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
