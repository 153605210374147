import React, { useState } from 'react';
import Courses from './Courses';
import Category from '../components/Category';
import { categoryList } from '../data/categoryData';
import courses from '../data/coursesData';
import { useNavigate } from 'react-router-dom';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [selectedCategoryDisplayId, setSelectedCategoryDisplayId] = useState<string | null>(null);

  // Filter courses based on the selected category
  const filteredCourses = selectedCategoryDisplayId
    ? courses.filter((course) => course.categoryDisplayId === selectedCategoryDisplayId)
    : courses;

  // Handle category selection and navigate to the Courses page
  const handleCategoryClick = (categoryDisplayId: string) => {
    setSelectedCategoryDisplayId(categoryDisplayId === selectedCategoryDisplayId ? null : categoryDisplayId); // Toggle category
    navigate(`/courses?course-category=${categoryDisplayId === selectedCategoryDisplayId ? '' : categoryDisplayId}`); // Navigate with categoryDisplayId
  };

  return (
    <div className="home">
      <Category categories={categoryList} onCategoryClick={handleCategoryClick} selectedCategoryDisplayId={selectedCategoryDisplayId} />
      <Courses courses={filteredCourses} />
    </div>
  );
};

export default Home;
