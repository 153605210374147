import React from 'react';
import './Category.css'; // Optional styling
import { CourseCategory } from '../data/categoryData';

interface CategoryProps {
  categories: CourseCategory[];
  onCategoryClick: (categoryDisplayId: string) => void;
  selectedCategoryDisplayId: string | null;
}

const Category: React.FC<CategoryProps> = ({ categories, onCategoryClick, selectedCategoryDisplayId }) => {
  return (
    <div className="categories">
      <h2>Explore Our Course Categories</h2>
      <div className="category-list">
        {categories.map((category) => (
          <div
            key={category.categoryDisplayId}
            className={`category-card ${selectedCategoryDisplayId === category.categoryDisplayId ? 'selected' : ''}`}
            onClick={() => onCategoryClick(category.categoryDisplayId)}
          >
            {/* Add Icon here */}
            <i className={`category-icon fa ${category.categoryIcon}`} />
            <h4>{category.name}</h4>
            <p title={category.description}>{category.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Category;
