import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { getCart } from '../services/CartService';

interface CartContextType {
  cartItems: number;
  updateCartItems: () => void;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [cartItems, setCartItems] = useState<number>(0);

  const updateCartItems = () => {
    const cart = getCart();
    setCartItems(cart.length);
  };

  useEffect(() => {
    updateCartItems();
    const handleCartUpdate = () => updateCartItems();
    window.addEventListener('cartUpdated', handleCartUpdate);

    return () => {
      window.removeEventListener('cartUpdated', handleCartUpdate);
    };
  }, []);

  return (
    <CartContext.Provider value={{ cartItems, updateCartItems }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = (): CartContextType => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
