import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import CourseCard from '../components/CourseCard';
import { Course } from '../data/coursesData';
import './Courses.css';

interface CoursesProps {
  courses: Course[];
}

const Courses: React.FC<CoursesProps> = ({ courses }) => {
  const [cartItems, setCartItems] = useState<number>(0);

  // Handle adding items to the cart
  const handleAddToCart = () => {
    setCartItems(cartItems + 1);
  };
  const [searchParams] = useSearchParams();
  const categoryDisplayId = searchParams.get('course-category');

  // State for search term and sorting option
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState<'asc' | 'desc'>('asc');

  // Function to handle search input
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle sort change
  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSortOption(event.target.value as 'asc' | 'desc');
  };

  // Function to clear the filters (search and sort)
  const clearFilters = () => {
    setSearchTerm('');
    setSortOption('asc');
  };

  // Filter courses based on the search term
  const filteredCourses = courses.filter(course => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    return (
      course.title.toLowerCase().includes(lowercasedSearchTerm) ||
      course.description.toLowerCase().includes(lowercasedSearchTerm) ||
      course.badgeText.toLowerCase().includes(lowercasedSearchTerm)
    );
  });

  // If categoryDisplayId is available, filter courses by categoryDisplayId first
  const filteredAndSortedCourses = categoryDisplayId
    ? filteredCourses.filter((course) => course.categoryDisplayId === (categoryDisplayId))
    : filteredCourses;

  // Sort courses by fee
  const sortedCourses = [...filteredAndSortedCourses].sort((a, b) => {
    if (sortOption === 'asc') {
      return a.fee - b.fee;
    } else {
      return b.fee - a.fee;
    }
  });

  return (
    <div className="courses">
                <h2 className='header'>{categoryDisplayId ? `Courses in Category ${categoryDisplayId}` : 'Explore Our Courses'}</h2>

      {/* Search Bar and Clear Filter Button */}
      <div className="search-sort-container">
        <input
          type="text"
          placeholder="Search courses..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-input"
        />

        {/* Clear Search Icon */}
        {searchTerm && (
          <button className="clear-button" onClick={clearFilters}>
            <span className="clear-icon">✖</span>
          </button>
        )}

        {/* Sort Dropdown */}
        <select value={sortOption} onChange={handleSortChange} className="sort-dropdown">
          <option value="asc">Price: Low to High</option>
          <option value="desc">Price: High to Low</option>
        </select>
      </div>

          {/* Display Courses */}
          <div className="courses-container">
            <div className="row">
              {sortedCourses.map((course) => (
                <div className="col-md-4 col-sm-6 mb-4" key={course.id}>
                  <CourseCard course={course} onAddToCart={handleAddToCart} />
                </div>
              ))}
            </div>
          </div>
        
    </div>
  );
};

export default Courses;
