import React, { useState, useEffect } from 'react';
import { getCart, removeFromCart, clearCart } from '../services/CartService';
import './CartPage.css';
<script src="https://checkout.razorpay.com/v1/checkout.js"></script>

interface CartItem {
  courseId: number;
  title: string;
  fee: number;
  quantity: number;
}

const CartPage: React.FC = () => {
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const total = cartItems.reduce((acc, item) => acc + item.fee * item.quantity, 0);

  useEffect(() => {
    const items = getCart();
    setCartItems(items);
  }, []);

  const handleRemoveFromCart = (courseId: number) => {
    removeFromCart(courseId);
    setCartItems(getCart());
  };

  const handleClearCart = () => {
    clearCart();
    setCartItems([]);
  };

  const handlePayment = async () => {
    try {
      const response = await fetch('http://mukundprogrammingtutorials.in/api/create-order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount: total }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to create order');
      }
  
      const order = await response.json();
  
      // Razorpay integration
      const options = {
        key: 'mukundprogrammingtutorials', // Replace with your Razorpay key ID
        amount: order.amount,
        currency: order.currency,
        name: 'mukund programming tutorials',
        description: 'Order Payment',
        order_id: order.id,
        handler: (response: any) => {
          alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
          handleClearCart();
        },
        prefill: {
          name: 'John Doe',
          email: 'john.doe@example.com',
          contact: '9999999999',
        },
        theme: {
          color: '#3399cc',
        },
      };
  
      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      alert('Failed to initiate payment. Please try again.');
    }
  };
  
  

  return (
    <div className="carts mt-5">
      <h2 className="header">Your Cart</h2>
      {cartItems.length === 0 ? (
        <p className="text-center">Your cart is empty. Add some items to your cart to get started!</p>
      ) : (
        <div className="cart-items">
          {cartItems.map((item) => (
            <div key={item.courseId} className="card mb-3 shadow-sm border-light">
              <div className="card-body d-flex justify-content-between align-items-center">
                <div>
                  <h5 className="card-title">{item.title}</h5>
                  <p className="card-text">Fee: ₹{item.fee}</p>
                  <p className="card-text">Quantity: {item.quantity}</p>
                </div>
                <div>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleRemoveFromCart(item.courseId)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          ))}
          <div className="d-flex justify-content-between align-items-center">
            <button className="btn btn-warning" onClick={handleClearCart}>
              Clear Cart
            </button>
            <div>
              <h4>Total: ₹{total.toFixed(2)}</h4>
            </div>
          </div>
          <button className="btn btn-primary mt-3" onClick={handlePayment}>
            Proceed to Pay ₹{total.toFixed(2)}
          </button>
        </div>
      )}
    </div>
  );
};

export default CartPage;
