// src/components/Footer.tsx
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="bg-light text-black text-center py-3 mt-5">
        <div className='footer-bg'></div>
      <div className="container">
        <p className="mb-0">© 2024 Mukund Programming Tutorials</p>
        <p className="mb-0">All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
