export interface CourseCategory {
    id: number;
    name: string;
    description: string;
    categoryDisplayId:string;
    categoryIcon:string;
  }
  export const categoryList: CourseCategory[] = [
    { id: 1, name: 'Web Development', description: 'Learn coding languages like Angular, React, JavaScript, and more.', categoryDisplayId: 'WebDevelopment', categoryIcon: 'fa-code' },
    { id: 2, name: 'Design', description: 'Master UI/UX design and graphic design skills.', categoryDisplayId: 'UI-UX', categoryIcon: 'fa-paint-brush' },
    { id: 3, name: 'Business', description: 'Enhance your business skills with management and marketing courses.', categoryDisplayId: 'Business', categoryIcon: 'fa-briefcase' },
    { id: 4, name: 'Data Science', description: 'Explore data analysis, machine learning, and AI.', categoryDisplayId: 'Data-Science', categoryIcon: 'fa-area-chart' },
    { id: 5, name: 'Digital Marketing', description: 'Learn SEO optimization, keywords tracking.', categoryDisplayId: 'Digital-Marketing', categoryIcon: 'fa-bullhorn' },
    { id: 6, name: 'Backend Development', description: 'Learn coding languages like Python, .Net, Java, NodeJs and more.', categoryDisplayId: 'BackendDevelopment', categoryIcon: 'fa-server' },
  ];
  