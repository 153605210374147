// src/pages/About.tsx
import React from 'react';
import './About.css'; // Optional: You can create a CSS file for styling

const About: React.FC = () => {
  return (
    <div className="about">
      <h1 className="header">Welcome to Mukund Programming Tutorials</h1>

      <p>
        Your go-to destination for comprehensive coding knowledge in Hindi and English! 🚀 
        Dive into the world of programming with our in-depth tutorials covering Java, Angular, JavaScript, Flask, Hostinger, AWS, MySQL, PHP, and more.
      </p>
      <p>
        🖥️ Whether you're a beginner or an experienced coder, our step-by-step guides will empower you to master the art of programming. 
        From building dynamic web applications with Angular to deploying scalable solutions on AWS, we've got you covered.
      </p>
      <p>
        🌐 Join our vibrant community of learners and stay ahead in the ever-evolving tech landscape. 
        Don't just code; create! Subscribe now for regular updates and elevate your programming skills with Mukund Programming Tutorials.
      </p>
      <p>
        🚀 Java | Angular | JavaScript | Flask | Hostinger | AWS | MySQL | PHP 🚀
      </p>
      <h2>Follow Us for More Updates!</h2>
      <p>
        #Coding #Programming #WebDevelopment #Java #Angular #JavaScript #Flask #Hostinger #AWS #MySQL #PHP #TechTutorials #CodeWithMukund #mukundprogrammingtutorials
      </p>
    </div>
  );
};

export default About;
